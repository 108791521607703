import React from 'react';
import { Link } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { relative } from 'path';
import { relativeTimeThreshold } from 'moment';
import avatar from '../../static/website-avatar.png';

class Layout extends React.Component {
  render() {
    const { location, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    const habidatumPath = `${__PATH_PREFIX__}/habidatum/`;
    const sympliPath = `${__PATH_PREFIX__}/sympli/`;
    const blogPath = `${__PATH_PREFIX__}/blog/`;

    const header = location.pathname === rootPath ? (
      <div>
        <TitleWrapper>
          <AvatarWrapper>
          <img
            src={avatar}
            alt="Alexander Katin"
            style={{
              margin: 0,
              padding: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </AvatarWrapper>
          <Title>
            <h1>
              <Link
                style={{
                  boxShadow: 'none',
                  textDecoration: 'none',
                  color: '#050505',
                }}
                to={location.pathname === blogPath ? '/blog/' : '/'}
              >
                  Alexander Katin
              </Link>
            </h1>
            <h2>
                Senior Product Designer
            </h2>
          </Title>
        </TitleWrapper>
      </div>
    ) : (
      <div>
        <TitleWrapper>
          <Title>
            <h2>
              <Link
                style={{
                  boxShadow: 'none',
                  textDecoration: 'none',
                  color: '#050505',
                }}
                to={location.pathname === blogPath ? '/blog/' : '/'}
              >
                  Back to projects
              </Link>
            </h2>
          </Title>
        </TitleWrapper>
      </div>
    );

    const footerContent = location.pathname === rootPath ? (
      <Footer>
        <p>
            This website was made
          {' '}
            with&nbsp;
          <a href="http://gatsbyjs.org">Gatsby.js</a>
,
          {' '}
          <a href="https://code.visualstudio.com">VS Code</a>,
          {' '}
          and <a href="https://notion.so">Notion</a> being used as a CMS for some pages. Text set in
          {' '}
          <a href="https://www.colophon-foundry.org/typefaces/mabry">
              Mabry
          </a>
          {' '}
font. Hosted on&nbsp;
          <a href="https://github.com">Github</a>
, deployed with&nbsp;
          <a href="https://www.netlify.com">Netlify</a>
.
          {' '}
        </p>
      </Footer>
    ) : (
      <Footer>
        <p>
          <Link
            style={{
              boxShadow: 'none',
              textDecoration: 'none',
              color: '#050505',
            }}
            to={location.pathname === blogPath ? '/blog/' : '/'}
          >
              Back to projects
          </Link>
        </p>
      </Footer>
    );

    return (
      <Wrapper>
        <GlobalStyle />
        <div className="holder">
          <header>{header}</header>
          <main>{children}</main>
        </div>
        {footerContent}

      </Wrapper>
    );
  }
}

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Konsole Expanded";
    src: url("${__PATH_PREFIX__}/fonts/Konsole/Konsole0.5-Expanded.woff2") format('woff2');
    font-display: block;
  }

  @font-face {
    font-family: "Mabry";
    src: url("${__PATH_PREFIX__}/fonts/Mabry/mabry-medium-pro.woff2") format('woff2');
    font-display: block;
  }

  @font-face {
    font-family: "Mabry Bold";
    src: url("${__PATH_PREFIX__}/fonts/Mabry/mabry-bold.woff2") format('woff2');
    font-display: block;
  }

  @font-face {
    font-family: "Inter Bold";
    src: url("${__PATH_PREFIX__}/fonts/Inter/Inter-Bold.woff2") format('woff2');
    font-display: block;
  }

  @font-face {
    font-family: "Inter Regular";
    src: url("${__PATH_PREFIX__}/fonts/Inter/Inter-Regular.woff2") format('woff2');
    font-display: block;
  }

  ::selection {
    background: #FFD84D;
    color: #000;
    text-shadow: none;
  }

  body {
    background: #FEFDF8;
    font-size: 16px;
    min-width: 320px;
  }

  .holder {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 60rem;
    padding: 0;
  }

  @media (max-width: 1280px) {

  }

  @media (max-width: 1024px) {
    .holder {
      width: auto;
      margin: 0 6rem;
    }
  }

  @media (max-width: 414px) {
    .holder {
      margin: 0 2rem;
    }
  }

  h1, h2, h3 {
    line-height: 1.17;
    margin-top: 0;
    color: #050505;
  }

  h1 {
    font-size: 4.5rem;
    margin: -1rem 0 .5rem;
    padding: 0 10rem;
    font-family: "Mabry", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  header h2 {
    padding: 0 10rem;
  }

  @media (max-width: 1280px) {
    h1 {
      font-size: 4rem;
    }
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 2.5rem;
      padding: 0;
      margin: -1rem 0 .5rem;
    }

    header h2 {
      font-size: 1.5rem;
      padding: 0;
    }
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: "Mabry", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  @media (max-width: 414px) {
    h2 {
      font-size: 1.75rem;
    }
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    font-family: "Mabry", Helvetica, Arial, sans-serif;
  }

  section {
    margin: 6rem 0;
  }

  section p, Footer p, section ol {
    font-size: 1.5rem;
    line-height: 1.44;
    color: #050505;
    font-family: Mabry, "Inter Regular", Helvetica, Arial, sans-serif;
  }

  section p,
  Footer p,
  section h2,
  section ol {
    padding: 0 2.5rem;
  }

  Footer p {
    font-size: 1.25rem;
  }

  section ol {
    margin: 0;
  }

  @media (max-width: 1024px) {
    section p,
    Footer p,
    section h2,
    section ol,
    Title p {
      padding: 0;
    }

    section ol {
      margin: 0 0 0 1rem;
    }
  }

  section a,
  footer a,
  h2 a {
    color: #050505;
    text-decoration: none;
    border-bottom: 2px solid #C0C0C0;
  }

  section a:hover,
  footer a:hover,
  h2 a:hover {
    border-bottom: 2px solid #050505;
  }

  section .small {
    font-size: 1rem;
    background: #DEDEDE;
    border-radius: 1.5rem;
    padding: .375rem .75rem;
  }

`;

const Wrapper = styled.div`
  min-height: 100vh;
`;

const AvatarWrapper = styled.div`
width: 6.25rem;
height: auto;
position: absolute;

@media (max-width: 1024px) {
  position: relative;
  margin: 0 0 3rem;
}
`;

const Footer = styled.footer`
  text-align: left;
  margin: 0 auto;
  padding: 0 0 4rem;
  width: 60rem;
  color: white;

  @media (max-width: 1280px) {
  }

  @media (max-width: 1024px) {
    margin: 0 6rem;
    width: auto;
  }

  @media (max-width: 414px) {
    margin: 0 2rem;
  }

  & p {
  }

  @media (max-width: 1024px) {
    & p {
      padding: 0;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: flex-start;
  margin: 2.5rem;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0;
  }
`;

const Title = styled.div`
  display: block;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 5.125rem;
  box-shadow: 0 0 0 1px white;
  border-radius: 1.75rem;
`;

const ControlLink = styled(Link)`
    font-family: "Mabry", Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1;
    color: white;
    width: 35.6%;
    height: 100%;
    vertical-align: center;
    padding: 1.75rem 2.5rem 1.25rem;
    text-decoration: none;
    box-sizing: border-box;
    &.active {
        background-color: white;
        color: black;
        :first-child {
          border-radius: 1.5rem 0 0 1.5rem;
        }
        :last-child {
          border-radius: 0 1.5rem 1.5rem 0;
        }
    }
`;

export default Layout;
